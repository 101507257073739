import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "контакты"
    }}>{`Контакты`}</h1>
    <p>{`Вы используете Дизайн-систему в своем продукте ВШЭ?`}</p>
    <p>{`Нашли ошибку в компоненте? `}<a parentName="p" {...{
        "href": "https://gitlab.com/proscom/hse-design-issues/-/issues/new?issuable_template=Bug"
      }}>{`Создайте баг репорт`}</a>{` в репозитории.`}</p>
    <p>{`У вас есть запрос на добавление или модификацию компонента? `}<a parentName="p" {...{
        "href": "https://gitlab.com/proscom/hse-design-issues/-/issues/new?issuable_template=Feature"
      }}>{`Создайте заявку`}</a>{` в репозитории.`}</p>
    <p>{`Непонятно, как оптимальнее использовать наши компоненты?`}</p>
    <p>{`Напишите нам:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mailto:designsystem@hse.ru"
        }}>{`designsystem@hse.ru`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://t.me/joinchat/D0zglRqgENSrLtkZFGbEWg"
        }}>{`Telegram`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      